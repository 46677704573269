* {
    box-sizing: border-box;
    padding: 0;
    margin: 0; 
}

body {
    font-family: Verdana, Arial, sans-serif;
    color: #1c1c1c;
    line-height: 1.5;
}

/*  */

.course-item {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 50%;
  }
  
.course-item td, .course-item th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
.course-item tr:nth-child(even){background-color: #f2f2f2;}
  
.course-item tr:hover {background-color: #ddd;}